
import './App.css';
import Aboutus from './components/Aboutus';
import CoreFeature from './components/CoreFeature';
import Footer from './components/Footer';
import Goal from './components/Goal';
import Header from './components/Header';
import Home from './components/Home';
import Map from './components/Map';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Aboutus/>
      <Goal/>
      <CoreFeature/>
      <Map/>
      <Footer/>
    </div>
  );
}

export default App;
