import React from 'react';

const Goal = () => {
    return (
        <>
            <section class="goal" id="Services">
                <div class="our-goal">
                    <div style={{ backgroundColor: 'rgb(0,0,0,0.4)' }}>
                        <div class="container goal">
                            <div class="row">
                                <div class="col-12 py-2">
                                    <h3 style={{ color: "#cdad43" }}><strong>Our Services</strong> </h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 goal-text" style={{ fontSize: "16px" }}>
                                    <p>A locker is a rented locker that a Shreesh Securities offers you to store your valuables. We aim to keep your assets safe and accessible. You have unlimited access to your Safe Deposite Locker allowing you can to use it as many as times as you want to.
                                    </p>

                                </div>
                            </div>
                            <div className="row mt-2" style={{textAlign:'start'}}>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon1.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            Secunex Next Gen Safe Deposit Lockers
                                        </div>
                                        <div className='service-details'>The most advanced lockers from the House of Global</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon2.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            Extended Working Hours
                                        </div>
                                        <div className='service-details'>9 AM - 9 PM (Mon - Sat) <br />9 AM - 12 PM (Sun)</div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon3.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            Unlimited Operations
                                        </div>
                                        <div className='service-details'>Ensures user convenience</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon5.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            Biometric System At Entrance
                                        </div>
                                        <div className='service-details'>Ensure the highest level of security</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon6.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            Keys, Diary And Pen
                                        </div>
                                        <div className='service-details'>To maintain your record of what you keep and what you take out</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon4.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            State-Of-The-Art Security
                                        </div>
                                        <div className='service-details'>Equipped with 24X7 surveillance</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon7.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            IntelliLight* In Locker
                                        </div>
                                        <div className='service-details'>Advanced motion activated LED light within each locker
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon8.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            Flexible Rental Schemes
                                        </div>
                                        <div className='service-details'>Designed to suit customers with different perspectives</div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon10.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            Extra Large Lockers
                                        </div>
                                        <div className='service-details'>Designed to suit specific needs of premium customers</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon9.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            Ample Parking Space
                                        </div>
                                        <div className='service-details'>Abundant amount of parking spaces available for vehicles</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon11.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            SMS Update On Entry & Exit
                                        </div>
                                        <div className='service-details'>Provide SMS service for Entry & Exit</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 d-flex gap-2 align-items-center my-2">
                                    <div className='services'><img src="assets/icons/icon12.png" alt="" className='service-icon' /></div>
                                    <div>
                                        <div className="service-title">
                                            24 X 7 Service
                                        </div>
                                        <div className='service-details'>service available at any time and usually, every day.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="row mt-4 goal-text text-start">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <div className='d-flex align-items-center gap-1'><div><SiTimescale className='service-icon'/></div> <div>Extended Working Hours</div></div>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <li>Secunex Next Gen Safe Deposit Lockers</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <li>Unlimited Operations</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <li>Additional Lock Feature</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <li>Flexible Rental Schemes</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <li>Biometric System At Entrance</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <li>State-Of-The-Art Security</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <li>SMS Update On Entry & Exit</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <ul className='ps-lg-0'>
                                        <li>IntelliLight* In Locker</li>
                                    </ul>
                                </div>
                                
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Goal
