import React from 'react'

const Aboutus = () => {
    return (
        <>
            <section class="feature" id="about">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-md-6 my-4">
                            <img class="image img-fluid" src="assets/about.jpg" width="480px" style={{ height: "360px" }} alt="" />
                        </div>
                        <div class="col-12 col-lg-6 col-md-6 align-self-center my-4">
                            <h6 class="title">About Us</h6>
                            <h1><strong> Shreesh Securities </strong></h1>
                            <p className='mb-md-0 mt-lg-3'>As a <strong>Locker owner</strong> , you can keep your valuables such as jewellery, important
                                documents,
                                valuable items etc. and secure them from any threat. The safe deposit lockers are insured and
                                provide immense security from various vulnerabilities</p><br />
                            <p className='mb-md-0'>A locker system is a storage locker with functionality beyond a simple key based access
                                method. </p>
                        </div>
                    </div>
                </div>

                <section class="app-down">
                    <div class="container">
                        <div class="app">
                            <div class="row">
                                <div class="col-12 col-md-7 col-lg-7">
                                    <div>
                                        <div class="app-body">
                                            <h5 class="title">Security</h5>
                                            <h5 class="app-text" style={{color:"#afa972"}}>Get high-security storage for your assets and valuables and one-of-a-kind peace of mind by storing with Shreesh Securities</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-5 col-lg-5 ">
                                    <img class="img-fluid app-img" src="assets/locker/l3.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default Aboutus
