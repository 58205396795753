import React, { useEffect } from 'react';
import { TbMailFilled } from "react-icons/tb";
import { BiSolidPhoneCall } from "react-icons/bi";

const Header = () => {
    const isSticky = (e) => {
        const header = document.querySelector('#header');
        const scrollTop = window.scrollY;
        scrollTop >= 100 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    return (
        <div>
            <section id="header" >
                <header>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <div className="row w-100">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <img src="assets/logo-sm.png" alt="" className="ps-lg-5 ps-2" style={{ height: "4.5rem" }} />
                                        <div className="px-1">
                                            <h4 className="fw-bold mb-1" style={{ color: "#2e4c9a" }}>Shreesh Securities</h4>
                                            <p className='mb-0' style={{ fontSize: "12px", color: "#cdad43" }} >A Unit Of : Shreesh Design Studio LLP</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 d-flex justify-content-md-end justify-content-center align-items-center">
                                    <div className='header-social'>
                                        <div className='d-flex'><div><TbMailFilled className='footer-link-icon' /></div> <a href="mailto:shreeshlockers@gmail.com">shreeshlockers@gmail.com</a></div>
                                        <div className='d-flex'><div><BiSolidPhoneCall className='footer-link-icon' /></div><a href="tel:8490017131">84900 17131</a> </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </nav>
                </header>
            </section>
        </div>
    )
}

export default Header
