import React from 'react'

const CoreFeature = () => {
  return (
    <>
      <section class="coreFeature" id="feature">
        <div class="container">
            <div class="row text-center">
                <div class="col-12">
                    <h1 style={{fontSize:"50px"}}><strong>Core Feature</strong></h1>
                </div>
            </div>
            <div class="row item justify-content-center">
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <img class="feature-img img-fluid" src="assets/locker/core2.jpg" alt="" />
                    <div class="coreFea-body">
                      
                        <h6 class="title">Sefaty</h6>
                        <h5>We provide security through CCTV cameras</h5>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <img class="feature-img img-fluid" src="assets/locker/core3.jpg" alt="" />
                    <div class="coreFea-body">
                       
                        <h6 class="title">Convenient locker sizes</h6>
                        <h5>Choose from a range of options <span style={{fontSize: "12px"}}>(Extra-small,Small, Medium, Large,
                                Extra-large)</span> </h5>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <img class="feature-img img-fluid" src="assets/locker/core1.jpg" alt="" />
                    <div class="coreFea-body">
                       
                        <h6 class="title">Easily accessible</h6>
                        <h5>24/7 you can access your locker with your key</h5>
                    </div>
                </div>

            </div>
        </div>
    </section>
    </>
  )
}

export default CoreFeature
