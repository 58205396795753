import React from 'react';
import { FaAngleRight } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { TbMailFilled } from "react-icons/tb";
import { BiSolidPhoneCall } from "react-icons/bi";

const Footer = () => {
    return (
        <>
            <section id="copyright" class="position-relative ">
                <div class="container-fluid">
                    <div className="row text-light px-xl-5 justify-content-center" >
                        <div className="col-lg-2 col-md-3">
                            <div className="footer-logo text-center">
                                <img src="assets/logo-light.png" style={{width:"100%"}} alt="" />
                                <div className='mt-3 text-center'>
                                    <h6 style={{ fontSize: '13px', color: "#202020" }}>SAFE WITH</h6>
                                    <img src="assets/godrej.png" width="170px" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-9 py-3">
                            <div className="row justify-content-center ">
                                <div className="col-lg-3 col-md-3 d-md-flex justify-content-center my-lg-0 my-3">
                                    <div className='footer-links'>
                                        <h5>Quick Links</h5>
                                        <div><FaAngleRight className='footer-link-icon' /><a href=''>Home</a></div>
                                        <div><FaAngleRight className='footer-link-icon' /><a href='#about'>About Us</a></div>
                                        <div><FaAngleRight className='footer-link-icon' /><a href="#Services">Our Services</a></div>
                                        <div><FaAngleRight className='footer-link-icon' /><a href="#feature">Core Feature</a></div>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-5 d-md-flex justify-content-center my-lg-0 my-3">
                                    <div>
                                        <div className="footer-links ">
                                            <h5>Our Ventures</h5>
                                            <a href='https://shreeshdesignstudiollp.com/' target='_blank'><img src="assets/shreesh-logo.png" alt="" height="50px" /></a>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-5 d-md-flex justify-content-center my-lg-0 my-3">
                                    <div>
                                        <div className="footer-links">
                                            <h5>Opening Hours</h5>
                                            <div><MdWatchLater className='footer-link-icon' />Mon-Sat : 9AM-9PM</div>
                                            {/* <div><MdWatchLater className='footer-link-icon' />Saturday : 10am-16pm</div> */}
                                            <div><MdWatchLater className='footer-link-icon' />Sunday : 9AM-12PM</div>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 d-md-flex justify-content-center my-lg-0 my-3" style={{ zIndex: "1" }}>
                                    <div className="footer-links" style={{ zIndex: "1" }}>
                                        <h5>Get In Touch</h5>
                                        <div className='d-flex'><div><FaLocationDot className='footer-link-icon mt-2' /></div><div>"Shreesh",
                                            Ground Floor, Dr. Dastur Marg,
                                            Next to Dr. Kakadiya Hospital
                                            Off. Tagore Road, Rajkot - 360001
                                            Gujarat, India</div></div>
                                        <div className='d-flex'><div><TbMailFilled className='footer-link-icon' /></div> <a href="mailto:shreeshlockers@gmail.com">shreeshlockers@gmail.com</a></div>
                                        <div className='d-flex'><div><BiSolidPhoneCall className='footer-link-icon' /></div><a href="tel:8490017131">84900 17131</a> </div>
                                        {/* <div><BsGlobe className='footer-link-icon' /><a href="http://www.shreeshlockers.com/">http://www.shreeshlockers.com/</a> </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div className='brochure'>
                <a href="assets/Shreesh-Securities-Brochure.pdf" download>Download Brochure</a>
            </div>
        </>
    )
}

export default Footer
