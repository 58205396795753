import React from 'react'

const Home = () => {
    return (
        <>
            <section>
                <div class="shreesh-app">
                    <div class="overlay"></div>
                    <div class="container">
                        <div class="row pt-5">
                            <div class="col-12 col-lg-12 col-sm-12 float-left ">
                                <div class="text">
                                    <img src="assets/logo-light.png" alt="" width='250px' /><br />
                                    <strong className='head-text'>Keep your valuables safe.</strong><br />
                                    <strong className='next-text'>Ensure peace of mind</strong><br />
                                    <strong className='next-text' style={{ color: '#ad8c3c', fontSize: "20px" }}>with</strong> <br />
                                    <img src="assets/godrej.png" alt="" width='250px' />
                                    <p style={{ color: "rgb(188, 186, 186)" }} class="mt-3 mb-3"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
